<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="list1"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="ข้อมูลสรุปจำนวนผู้ตอบแบบสอบถาม.xls"
        :header="'ข้อมูลสรุปจำนวนผู้ตอบแบบสอบถาม'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        Downloadสรุปจำนวนการตอบแบบสอบถาม
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["list1"],
  data() {
    return {
      json_fields: {
        "ชื่อสถานี": "station",
        "จังหวัด": "province",
        "ภาค": "group",
        "จำนวนผู้ Scan Qrcode แล้วตอบแบบสอบถาม": "countDoneAmt",
        "จำนวนผู้ Scan Qrcode แล้วไม่ตอบแบบสอบถาม": "countNotDoneAmt",
        // ไม่ได้กรอก: "notInput",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
