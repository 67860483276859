<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4 mt-10"
      ><download-excel
        class="btn btn-default"
        :data="itemExportData"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="เอกสารสรุปข้อมูลการประเมินสภาพชุมชน.xls"
        :header="'ข้อมูลการประเมินสภาพชุมชน'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        โหลดข้อมูลการประเมินสภาพชุมชนทั้งหมด
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["itemExportData"],
  data() {
    return {
      json_fields: {
        ชุมชน: "villageName",
        สถานี: "station",
        อำเภอ: "district",
        ตำบล: "subDistrict",
        จังหวัด: "province",
        ภาค: "group",
        เพศ: "gender",
        อายุ: "age",
        ระดับการศึกษา: "education",
        ลักษณะที่พักอาศัยปัจจุบัน: "currentHousing",
        "ท่านอาศัยอยู่ในชุมชน/หมู่บ้านนี้มาเป็นเวลาเท่าใด": "liveTime",
        ปัจจุบันท่านประกอบอาชีพอะไร: "career",
        "ท่านอาศัยอยู่ในชุมชน/หมูบ้านนี้มีความสุขมากน้อยเพียงใด":
          "happyLevel",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีการขายยาเสพติดหรือไม่":
          "drugSales",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้เสพยาเสพติดหรือไม่":
          "takenDrugs",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีปัญหาที่เกี่ยวข้องกับยาเสพติด เช่น ผู้สมคบ ผู้สนับสนุน ผู้ให้ความช่วยเหลือหรือไม่":
          "contributor",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ครอบครอง อาวุธปืนเถือน/วัตถุระเบิด/อาวุธสงครามหรือไม่":
          "possessWeapon",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้มีพฤติกรรมชอบเล่นการพนัน/ขายหวยออนใต้ดินหรือไม่":
          "gambling",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ที่มีพฤติกรรมลักลอบตัดไม้หวงห้าม/ไม่มีค่าหรือไม่":
          "cutWood",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ที่มีพฤติกรรมบุกรุกที่สาธารณะ/ทำลายทรัพยากรธรรมชาติหรือไม่":
          "destroyNatural",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ที่มีพฤติกรรมลักลอบจำหน่ายสินค้าหนีภาษีหรือไม่":
          "smuggledGoods",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ที่มีพฤติกรรมออกเงินกู้นอกระบบหรือไม่":
          "informalLoan",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ที่มีพฤติกรรมเป็นผู้มีอิทธิพลหรือไม่":
          "influencer",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีผู้ที่มีพฤติกรรมการขับรถแว้น คึกคะนอง เสียงดัง หรือก่อความรำคานหรือไม่":
          "noisyDriving",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีที่มีพฤติกรรมลักเล็กขโมยน้อยหรือไม่":
          "stealing",
        "สภาพปัญหาที่เกิดในชุน/หมู่บ้านที่ท่านอาศัยอยู่ มีที่มีพฤติกรรมจับกลุ่มดื่มสุรามั่วสุม/ชอบนำพวกทะเลาะวิวาทหรือไม่":
          "mingle",
        "ปัญหาอื่นๆ โปรดระบุ": "otherProblems",
        "ท่านคิดว่าสถานการณ์การแผ่ระบาดยาเสพติดในชุมชนท่านอยู่ระดับใด":
          "drugEpidemic:",
        "ท่านคิดว่าปัญหายาเสพติดจะลดลงหรือหมดไปจากชุมชนอย่างยั่งยืนได้อย่างไร":
          "reducedDrugProblem",
        "วันที่ทำแบบสอบถาม": "createdAt",
        // ไม่ได้กรอก: "notInput",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
