<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <h2 class="headtitle">สรุปจำนวนผู้ตอบแบบสอบถาม</h2>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-select
          v-model="selectForm"
          :items="selectFormItems"
          item-text="text"
          item-value="value"
          outlined
          dense
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="opendiv199">
      <v-col cols="12" md="12" sm="12" xs="12">
        <ExportAllAss
          v-if="showExportAll"
          :itemExportData="exportCustomFormExcel"
        />
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          @change="onChangeArea()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="opendiv2">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="opendiv3">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="opendiv4">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-text-field
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-text-field
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="text"
          item-value="province"
          disabled
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-text-field
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="text"
          item-value="station"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-menu
          ref="menufrom"
          v-model="menufrom"
          :close-on-content-click="false"
          :return-value.sync="datefrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="datefrom"
              label="วันที่เริ่ม"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="datefrom" no-title scrollable>
            <v-btn text color="primary" @click="menufrom = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menufrom.save(datefrom)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-menu
          ref="menuto"
          v-model="menuto"
          :close-on-content-click="false"
          :return-value.sync="dateto"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateto"
              label="วันที่สิ้นสุด"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateto" no-title scrollable>
            <v-btn text color="primary" @click="menuto = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menuto.save(dateto)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-col cols="12" md="12" sm="12" xs="12">
      <v-row align="center" justify="center">
        <v-btn width="80px" class="mt-2 mb-4 mr-4" @click="clear()"
          >เคลียร์</v-btn
        >
        <v-btn
          width="80px"
          class="mt-2 mb-4"
          @click="getReportXray()"
          color="#833133"
          dark
          >ค้นหา</v-btn
        >
      </v-row>
    </v-col>
    <div v-if="showTable">
      <v-row align="center" justify="center">
        <v-col cols="12" md="4" sm="12" class="mr-4">
          <v-text-field
            class="mb-4"
            v-model="search"
            dense
            hide-details
            outlined
            placeholder="ค้นหา สภ./จังหวัด/ภาค"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="4" sm="12" class="mr-10 mb-4"
          ><ExportAss :list1="list1"
        /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :headers="headers"
            :items="list1"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            :mobile-breakpoint="0"
          >
            <!-- <template v-slot:[`item.notInput`]="{ item }">
              <span v-if="item.notInput == null">0</span>
              <span v-else>{{ item.notInput }}</span>
            </template>
            <template v-slot:[`item.found`]="{ item }">
              <span v-if="item.found == null">0</span>
              <span v-else>{{ item.found }}</span>
            </template>
            <template v-slot:[`item.notFound`]="{ item }">
              <span v-if="item.notFound == null">0</span>
              <span v-else>{{ item.notFound }}</span>
            </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { Decode, Encode } from "@/services";
import ExportAss from "@/views/ExportExcel/ExportAss";
import ExportAllAss from "@/views/ExportExcel/ExportAllAss";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: { ExportAss, ExportAllAss, Loading },
  data() {
    return {
      showTable: false,
      search: "",
      list1: [],
      count: 0,
      headers: [
        { text: "ชื่อสถานี", value: "station", align: "center" },
        { text: "จังหวัด", value: "province", align: "center" },
        { text: "ภาค", value: "group", align: "center" },
        {
          text: "จำนวนผู้ Scan Qrcode แล้วตอบแบบสอบถาม",
          value: "countDoneAmt",
          align: "center",
        },
        {
          text: "จำนวนผู้ Scan Qrcode แล้วไม่ตอบแบบสอบถาม",
          value: "countNotDoneAmt",
          align: "center",
        },
        // { text: "ไม่ได้กรอก", value: "notInput", align: "center" },

        // notInput: 635
      ],
      opendiv199: false,
      opendiv2: false,
      opendiv3: false,
      opendiv4: false,
      items: [],
      items2: [
        { text: "ทั่วประเทศ", group: "" },
        { text: "นครบาล", group: "น." },
        { text: "ภาค1", group: "ภ.1" },
        { text: "ภาค2", group: "ภ.2" },
        { text: "ภาค3", group: "ภ.3" },
        { text: "ภาค4", group: "ภ.4" },
        { text: "ภาค5", group: "ภ.5" },
        { text: "ภาค6", group: "ภ.6" },
        { text: "ภาค7", group: "ภ.7" },
        { text: "ภาค8", group: "ภ.8" },
        { text: "ภาค9", group: "ภ.9" },
      ],
      items3: [],
      items4: [],
      data: {
        area: "",
        province: "",
        station: "",
      },
      checklogin: "",
      showExportAll: false,
      exportCustomFormExcel: [],
      datefrom: new Date().toISOString().substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      menufrom: false,
      menuto: false,
      selectForm: "",
      selectFormItems: [
        {
          text: "เลือกแบบประเมิน",
          value: "",
        },
        {
          text: "แบบประเมินสภาพชุมชน สำหรับประชาชน",
          value: "getReportQuery",
        },
        {
          text: "แบบประเมินสภาพชุมชน สำหรับชุดปฏิบัติการ",
          value: "getReportQueryOfficer",
        },
        {
          text: "แบบประเมินความพึงพอใจ (สำหรับประชาชนในพื้นที่)",
          value: "getReportQueryPhase2",
        },
        {
          text: "แบบประเมินความพึงพอใจ (สำหรับชุดปฏิบัติการที่ลงปฏิบัติในพื้นที่)",
          value: "getReportQueryPhase2Officer",
        },
      ],
      loading: false,
    };
  },

  created() {
    this.getUser();
    this.datefrom = new Date("2021-04-02").toISOString().substr(0, 10);
    // this.getReportXray();
    // this.searchDropdown();
  },
  methods: {
    clear() {
      this.datefrom = new Date("2021-04-02").toISOString().substr(0, 10);
      this.data.area = "";
      this.data.province = "";
      this.data.station = "";
      this.getUser();
      this.getReportXray();
    },
    async onChangeArea() {
      this.items3 = [];
      this.items4 = [];
      this.data.province = "";
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?group=` + this.data.area
      );
      console.log("onChangeArea", response.data.data);
      this.items3 = response.data.data;

      // this.items4 = response.data.data;
    },
    async onChangeProvince() {
      this.items4 = [];
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?province=` +
          this.data.province
      );
      this.items4 = response.data.data;

      // this.items4 = response.data.data;
    },
    async searchDropdown() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);

      this.data.area = Array.from(
        new Set(this.items.map((a) => a.group.trim()))
      ).map((group) => {
        return this.items.find((a) => a.group.trim() === group.trim());
      });
      console.log("this.item2", this.data.area);
    },
    async getUser() {
      this.list1 = [];
      this.checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      console.log(this.checklogin);
      // var data;

      if (this.checklogin.userType == "1" || this.checklogin.userType == "99") {
        this.opendiv199 = true;
      } else if (this.checklogin.userType == "2") {
        this.data = {
          area: this.checklogin.area || "",
          province: "",
          station: "",
        };
        this.onChangeArea();
        this.opendiv2 = true;
      } else if (this.checklogin.userType == "3") {
        this.data = {
          area: this.checklogin.area || "",
          province: this.checklogin.province || "",
          station: "",
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response.data.data;
        this.onChangeProvince();

        // console.log(this.data);
        this.opendiv3 = true;
      } else if (this.checklogin.userType == "4") {
        (this.data.area = this.checklogin.area || ""),
          (this.data.province = this.checklogin.province || "");
        this.data.station = this.checklogin.station;
        this.opendiv4 = true;
        console.log("this.data.station", this.data.station);
      }
      // this.getReportXray();
      if (this.checklogin.userType == "99") {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/assessCommunity/getAll`
        );
        // console.log("onChangeArea", response.data.data);
        this.exportCustomFormExcel = response.data.data;
        this.showExportAll = true;
      }
    },
    async getReportXray() {
      if (this.selectForm !== "") {
        this.loading = true;
        this.showTable = true;
        console.log("xrayReport?group=", this.data);
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/assessCommunity/` +
            this.selectForm +
            `/?group=` +
            this.data.area +
            "&province=" +
            this.data.province +
            "&station=" +
            this.data.station +
            "&fromDate=" +
            this.datefrom +
            "&toDate=" +
            this.dateto
        );
        console.log("data", response.data.data.countData);
        this.list1 = response.data.data.countData;
        for (let i in this.list1) {
          this.list1[i].count = parseInt(i) + 1;
          console.log(this.list);
        }
        this.loading = false;
      } else {
        this.$swal.fire({
          icon: "error",
          title: `กรุณาเลือกแบบสอบถาม`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
